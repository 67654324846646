import React, { useEffect } from "react";

import { api } from "@meterup/proto";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { Outlet } from "react-router-dom";

import useIdentifiedUser from "../hooks/useIdentifiedUser";
import { useToWithQuery } from "../hooks/useToWithQuery";
import { OTPAuthenticationValidationResponse } from "../vendor/portal";

export default function RedirectToService() {
  const identifiedUser = useIdentifiedUser();
  const redirectUriApiPath = useToWithQuery("/v1/redirect-uri");
  const { data: redirectUriData } = useQuery<
    unknown,
    AxiosError<api.Error>,
    OTPAuthenticationValidationResponse
  >(["redirect_uri"], () => axios.get(redirectUriApiPath).then((r) => r.data));

  useEffect(() => {
    const { identity } = identifiedUser;
    if (!identity || !redirectUriData) {
      return;
    }
    const { redirect_uri: redirectUri } = redirectUriData;

    if (identity.company_memberships.length > 0 && redirectUri) {
      window.location.href = redirectUri;
    }
  }, [identifiedUser, redirectUriData]);

  return <Outlet />;
}
