import "../css/icons.css";
import "../css/index.css";
import "@meterup/metric/dist/index.css";

import React from "react";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import TagManager from "react-gtm-module";

import { NotificationProviderComponent } from "./components/NotificationProviderComponent";
import Layout from "./Layout";
import App from "./pages/App";
import ConfirmCode from "./pages/ConfirmCode";
import EnterCompany from "./pages/EnterCompany";
import EnterEmail from "./pages/EnterEmail";
import { RedirectToRoot } from "./pages/RedirectToRoot";
import RedirectToService from "./pages/RedirectToService";
import SSO from "./pages/SSO";
import IdentifiedUserProvider from "./providers/IdentifiedUserProvider";


if (import.meta.env.NODE_ENV === "production") {
  const tagManagerArgs = {
    gtmId: "GTM-PK38LLR",
  };

  TagManager.initialize(tagManagerArgs);

  Sentry.init({
    dsn: "https://f96b7c83aab2467ab3d23cecc8d26b4b@o322827.ingest.sentry.io/4504176335912960",
    enabled: true,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 0.25,
  });
}

const queryClient = new QueryClient({});
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <SentryRoutes>
          <Route element={<NotificationProviderComponent />}>
            <Route element={<IdentifiedUserProvider />}>
              <Route path="/" element={<Layout />}>
                <Route index element={<App />} />
                <Route path="email" element={<EnterEmail />} />
                <Route path="email/:email/verify" element={<ConfirmCode />} />
                <Route element={<RedirectToService />}>
                  <Route path="company" element={<EnterCompany />} />
                  <Route path="company/:companySID" element={<EnterCompany />} />
                </Route>
                <Route path="sso" element={<SSO />} />
                <Route path="*" element={<RedirectToRoot />} />
              </Route>
            </Route>
          </Route>
        </SentryRoutes>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement,
);
