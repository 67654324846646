import { useCallback } from "react";

import { resolvePath, useLocation, useSearchParams } from "react-router-dom";

export const RELEVANT_QUERY_KEYS = ["via", "path", "wo"];

export function useToWithQueryFn() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  return useCallback(
    (to: string) => {
      const newParams = new URLSearchParams();
      const resolvedTo = resolvePath(to, location.pathname);
      RELEVANT_QUERY_KEYS.forEach((key) => {
        const val = searchParams.get(key);
        if (val) {
          newParams.set(key, val);
        }
      });

      return `${resolvedTo.pathname}?${newParams.toString()}`;
    },
    [location, searchParams],
  );
}
export function useToWithQuery(to: string) {
  return useToWithQueryFn()(to);
}
