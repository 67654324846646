import React, { useCallback, useMemo } from "react";

import { Alert, Badge, CaptivePageLayout, HStack, Icon, Logo, Text, VStack } from "@meterup/metric";
import { styled } from "@stitches/react";
import { Link, Outlet, useSearchParams } from "react-router-dom";

import { useIsWorkOS } from "./appOptions";
import useIdentifiedUser from "./hooks/useIdentifiedUser";
import useNotification from "./hooks/useNotification";
import { useToWithQuery } from "./hooks/useToWithQuery";
import Notification from "./Notification";

const Wrapper = styled("div", {
  "& > div": {
    minWidth: "100vw",
  },

  "& > div > div > div > div": {
    "@media (max-width: 414px)": {
      paddingLeft: "$16",
      paddingRight: "$16",
    },
  },
});

const textGray = {
  light: "gray-500",
} as const;

const StyledLink = styled("a", {
  color: "$blue-600",
  cursor: "pointer",

  "&:hover": {
    textDecoration: "underline",
  },

  variants: {
    role: {
      sub: {
        fontSize: "$14",
      },
    },
  },
});

export default function Layout() {
  const { notification } = useNotification();
  const identifiedUser = useIdentifiedUser();

  const [searchParams] = useSearchParams();
  const toWithQuery = useToWithQuery(".");
  const onClickLogout = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      identifiedUser.logout(toWithQuery);
    },
    [identifiedUser, toWithQuery],
  );
  const sideContent = useMemo(() => {
    if (searchParams.get("via") === "connect") {
      return (
        <VStack spacing={16}>
          <Text color={{ light: "gray-800" }} weight="medium" size={16}>
            Why use Meter Connect?
          </Text>
          <VStack spacing={12}>
            <Badge variant="brand" icon="checkmark" arrangement="leading-icon">
              Unlimited free quotes
            </Badge>
            <Badge variant="brand" icon="checkmark" arrangement="leading-icon">
              Price match if you find better pricing
            </Badge>
            <Badge variant="brand" icon="checkmark" arrangement="leading-icon">
              Expert & unbiased support and recommendations
            </Badge>
            <Badge variant="brand" icon="checkmark" arrangement="leading-icon">
              Create your contract hassle-free
            </Badge>
          </VStack>
        </VStack>
      );
    }

    return null;
  }, [searchParams]);
  const isWorkOS = useIsWorkOS();

  return (
    <Wrapper>
      <CaptivePageLayout
        sideContent={sideContent}
        content={
          <>
            <div className="m-mb-8">
              <Logo height={6} />
            </div>
            <div className="m-flex m-flex-col m-gap-y-4 m-mb-8">
              <Notification notification={notification} />
              {isWorkOS ? (
                <Alert
                  variant="neutral"
                  icon="information"
                  copy="Using our new SSO experience."
                />
              ) : null}
              <Outlet />
            </div>
            <HStack spacing={8}>
              <Text color={textGray} size={14}>
                Don't have an account?
              </Text>
              <StyledLink href="https://www.meter.com" role="sub">
                Learn more <Icon icon="arrowRight" size={14} />
              </StyledLink>
            </HStack>
          </>
        }
        footer={
          <HStack spacing={6}>
            <Text color={textGray}>Meter, Inc. •</Text>
            <StyledLink href="https://www.meter.com/support">Support &amp; FAQs</StyledLink>

            {identifiedUser.identity && identifiedUser.identity.company_memberships.length > 0 ? (
              <>
                <Text color={textGray}>•</Text>
                <StyledLink
                  as={Link}
                  to={toWithQuery}
                  onClick={onClickLogout}
                  className="hover:m-underline m-font-sans m-text-blue-600 dark:m-text-blue-400">
                  Log out
                </StyledLink>
              </>
            ) : null}
          </HStack>
        }
      />
    </Wrapper>
  );
}
