import React, { useEffect } from "react";

import { Outlet, useLocation } from "react-router-dom";

import { appOptions, useAppOptions } from "../appOptions";
import useNotification from "../hooks/useNotification";
import NotificationProvider from "../providers/NotificationProvider";
import { NotificationWrapperType } from "../types/NotificationContext";

type NotificationProviderComponentType = {
  defaultNotification?: NotificationWrapperType;
};

function NotificationStateManager() {
  const location = useLocation();
  const { notification, removeNotification } = useNotification();

  useEffect(() => {
    if (notification) {
      removeNotification();
    }
  }, [location, removeNotification]);

  return null;
}

export function NotificationProviderComponent({
  defaultNotification,
}: NotificationProviderComponentType) {
  const options = useAppOptions();
  let notification = defaultNotification;
  if (options && options.Error && !notification) {
    notification = {
      message: appOptions.Error,
      title: appOptions.ErrorTitle,
      type: "error",
    };
  }
  return (
    <NotificationProvider defaultNotification={notification}>
      <NotificationStateManager />
      <Outlet />
    </NotificationProvider>
  );
}
