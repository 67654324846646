import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import { Button, TextInput, VStack } from "@meterup/metric";
import { api } from "@meterup/proto";
import {
  MagicLinkAuthentication,
  MagicLinkAuthenticationErrorTypes,
  MagicLinkSuccessResponse,
} from "@meterup/proto/esm/portal";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import { useLoginWithEmailMutation } from "../hooks/useLoginWithEmailMutation";
import { useMakeMutationArgs } from "../hooks/useMakeMutationArgs";
import useNotification from "../hooks/useNotification";
import { useToWithQueryFn } from "../hooks/useToWithQuery";
import LinkWithQuery from "../LinkWithQuery";
import { logError } from "../logError";
import { MagicLinkErrorTypes } from "../proto";
import TagManager from "react-gtm-module";
import {useViaApp} from "../appOptions";

const EMAIL_RE =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

type FieldErrorsType = {
  companyName: boolean;
  emailAddress: boolean;
};

export default function EnterEmail() {
  const [emailAddress, setEmailAddress] = useState("");
  const toWithQuery = useToWithQueryFn();
  const navigate = useNavigate();
  const viaApp = useViaApp();
  const { addNotification, removeNotification } = useNotification();
  const submitLoginMutation = useLoginWithEmailMutation({
    onError: logError,
    onSuccess: ({ email }) => {
      TagManager.dataLayer({
        dataLayer: { event: "conversion", via: viaApp },
      });
      removeNotification();
      const toVerify = toWithQuery(`/email/${encodeURIComponent(email)}/verify`);
      navigate(toVerify, { state: { email } });
    },
  });
  const createUserMutation = useMutation<
    MagicLinkSuccessResponse,
    AxiosError<api.Error>,
    MagicLinkAuthentication
  >((params) => axios.post("/v1/users", params).then((r) => r.data as MagicLinkSuccessResponse), {
    onError: logError,
  });

  const [companyName, setCompanyName] = useState("");
  const [shouldShowCompanyField, setShouldShowCompanyField] = useState(false);
  const mutationArgs = useMakeMutationArgs<MagicLinkAuthentication>({
    email: emailAddress,
    company_name: companyName,
  });
  const companyInputRef = useRef<HTMLInputElement>();
  const [fieldErrors, setFieldErrors] = useState<FieldErrorsType>({
    companyName: false,
    emailAddress: false,
  });
  const updateFieldErrors = useCallback(
    (values?: Partial<FieldErrorsType>) => {
      const emailValid = EMAIL_RE.test(emailAddress);
      const companyNameValid = !shouldShowCompanyField || companyName.length > 0;
      const errors = {
        companyName: values?.companyName ?? !companyNameValid,
        emailAddress: values?.emailAddress ?? !emailValid,
      };
      setFieldErrors(errors);
      return errors;
    },
    [companyName.length, emailAddress, shouldShowCompanyField],
  );

  const onEmailSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      const errors = updateFieldErrors();
      if (errors.emailAddress) {
        addNotification({
          message: "Please enter a valid email to sign in.",
          title: "Invalid email",
          type: "error",
        });
        return;
      }

      submitLoginMutation.mutate(mutationArgs, {
        onError: (error) => {
          const status = error.response?.data.status;
          if (status === MagicLinkErrorTypes[MagicLinkAuthenticationErrorTypes.USER_NOT_FOUND]) {
            console.log("User not found, creating user", error);
            createUserMutation.mutate(mutationArgs, {
              onSuccess: (data) => {
                console.log("Created user", data);
                submitLoginMutation.mutate(mutationArgs);
              },
              onError: (error) => {
                console.log("Error creating user", error);
              },
            });
          } else if (
            status === MagicLinkErrorTypes[MagicLinkAuthenticationErrorTypes.COMPANY_NOT_FOUND]
          ) {
            console.log("Company not found", error);
            setShouldShowCompanyField(true);
            setFieldErrors({ companyName: false, emailAddress: false });
            addNotification({
              message: "Please enter a name for your company to sign up.",
              title: "User not found",
              icon: "warning",
              type: "alert",
            });
          } else if (
            status === MagicLinkErrorTypes[MagicLinkAuthenticationErrorTypes.EMAIL_INVALID]
          ) {
            addNotification({
              message: "Please enter a valid email to sign in.",
              title: "Invalid email",
              type: "error",
            });
          } else {
            addNotification({
              message: "Something went wrong, please try again shortly.",
              title: "Something went wrong",
              type: "error",
            });
          }
        },
      });
    },
    [
      updateFieldErrors,
      submitLoginMutation,
      companyName,
      emailAddress,
      addNotification,
      removeNotification,
      navigate,
    ],
  );
  const onCompanySubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      const errors = updateFieldErrors();
      if (errors.emailAddress || errors.companyName) {
        if (errors.companyName) {
          addNotification({
            message: "Please enter a company name to sign in.",
            title: "Invalid company name",
            type: "error",
          });
        }
        return;
      }

      try {
        const createResult = await createUserMutation.mutateAsync(mutationArgs);
        const { email } = createResult;
        removeNotification();
        submitLoginMutation.mutate({ email });
      } catch (error) {
        logError(error);
      }
    },
    [
      addNotification,
      companyName,
      createUserMutation,
      emailAddress,
      navigate,
      removeNotification,
      updateFieldErrors,
    ],
  );
  useEffect(() => {
    if (shouldShowCompanyField) {
      companyInputRef.current?.focus();
    }
  }, [shouldShowCompanyField]);

  return (
    <VStack spacing={32}>
      {shouldShowCompanyField ? (
        <form onSubmit={onCompanySubmit}>
          <VStack spacing={16}>
            <VStack spacing={8}>
              <label className="m-text-gray-500 m-text-xxs">Email address</label>
              <TextInput
                id="email-address"
                aria-label="email address"
                icon="email"
                type="email"
                value={emailAddress}
                controlSize="large"
                isRequired
                minLength={5}
                hasError={fieldErrors.emailAddress}
                onChange={setEmailAddress}
              />
              <>
                <label className="m-text-gray-500 m-text-xxs">Company name</label>
                <TextInput
                  id="company-name"
                  aria-label="company name"
                  type="text"
                  value={companyName}
                  controlSize="large"
                  isRequired
                  minLength={1}
                  hasError={fieldErrors.companyName}
                  onChange={setCompanyName}
                  ref={(ref) => {
                    let inputChild: HTMLInputElement | undefined;
                    if (ref) {
                      inputChild = ref.querySelector("input") || undefined;
                    }
                    companyInputRef.current = inputChild;
                  }}
                />
              </>
            </VStack>
            <Button type="submit" width="full" size="large" loading={submitLoginMutation.isLoading}>
              Sign up with email
            </Button>
          </VStack>
        </form>
      ) : (
        <form onSubmit={onEmailSubmit}>
          <VStack spacing={16}>
            <VStack spacing={8}>
              <label className="m-text-gray-500 m-text-xxs">Email address</label>
              <TextInput
                id="email-address"
                aria-label="email address"
                icon="email"
                type="email"
                value={emailAddress}
                controlSize="large"
                minLength={5}
                hasError={fieldErrors.emailAddress}
                onChange={setEmailAddress}
              />
            </VStack>
            <Button type="submit" width="full" size="large" loading={submitLoginMutation.isLoading}>
              Sign in with email
            </Button>
          </VStack>
        </form>
      )}
      <Button
        as={LinkWithQuery}
        to="/"
        direction="previous"
        variant="tertiary"
        css={{
          color: "$blue-600",
          boxShadow: "unset",
          paddingLeft: 0,
          "&:hover": { boxShadow: "unset" },
          "& span, & svg": { color: "$blue-600" },
        }}>
        {" "}
        Other login options{" "}
      </Button>
    </VStack>
  );
}
