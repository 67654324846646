import React from "react";

import { api } from "@meterup/proto";
import { QueryObserverResult } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { IdentifiedUserContextType } from "../types/IdentifiedUserContext";

const IdentifiedUserContext = React.createContext<IdentifiedUserContextType>({
  logout: () => {},
  refetch: (): Promise<QueryObserverResult<api.IdentityResponse, AxiosError<api.Error, any>>> =>
    Promise.resolve() as any,
});

export default IdentifiedUserContext;
