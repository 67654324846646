import { api } from "@meterup/proto";
import { MagicLinkAuthentication, MagicLinkSuccessResponse } from "@meterup/proto/esm/portal";
import { useMutation, UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import { logError } from "../logError";

const m: UseMutationResult<
  MagicLinkSuccessResponse,
  AxiosError<api.Error, any>,
  MagicLinkAuthentication,
  unknown
> | null = null;

const DEFAULT_OPTIONS = {
  onError: logError,
};

export function useLoginWithEmailMutation(
  opts?: Omit<
    UseMutationOptions<MagicLinkSuccessResponse, AxiosError<api.Error>, MagicLinkAuthentication>,
    "mutationFn"
  >,
) {
  return useMutation<MagicLinkSuccessResponse, AxiosError<api.Error>, MagicLinkAuthentication>(
    (params) =>
      axios
        .post("/v1/authenticate", params, { maxRedirects: 0 })
        .then((r) => r.data as MagicLinkSuccessResponse),
    opts ?? DEFAULT_OPTIONS,
  );
}
