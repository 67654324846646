import React, { useCallback, useState } from "react";

import {
  Button,
  HStack,
  Label,
  LoadingIcon,
  Text,
  TextInput,
  theme,
  VStack,
} from "@meterup/metric";
import { api, portal } from "@meterup/proto";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import useIdentifiedUser from "../hooks/useIdentifiedUser";
import { useToWithQuery } from "../hooks/useToWithQuery";
import { OTPAuthenticationValidationResponse } from "../vendor/portal";

const { styled } = theme;

const FormFooter = styled(HStack, {
  borderTop: "1px solid $gray-100",
  paddingTop: "$12",
});

export default function EnterCompany() {
  // Make queries to /api-proxy/v1/identity to get list of companies and current email. Then if no companies, do company
  // onboarding. If there are companies, navigate to /company/:companyId.
  const identity = useIdentifiedUser();
  const user = identity?.identity;
  const [companyName, setCompanyName] = useState("");

  const createCompanyMutation = useMutation<
    api.UserResponse,
    api.Error,
    portal.MagicLinkAuthentication
  >((params) => axios.put("/api-proxy/v1/companies/users/stytch", params).then((r) => r.data));

  const redirectUri = useToWithQuery("/v1/redirect-uri");
  const { data: redirectUriData, isLoading } = useQuery<
    unknown,
    AxiosError<api.Error>,
    OTPAuthenticationValidationResponse
  >(["redirect_uri"], () => axios.get(redirectUri).then((r) => r.data));
  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const userName = user?.username;
      if (!userName) {
        return;
      }
      createCompanyMutation.mutate(
        { company_name: companyName, email: userName },
        {
          onSuccess: (resp) => {
            if (resp.company_slug && redirectUriData?.redirect_uri) {
              const url = new URL(redirectUriData.redirect_uri);
              window.location.href = `${url.origin}/dashboard/setup`;
            }
          },
        },
      );
    },
    [companyName, createCompanyMutation, redirectUriData?.redirect_uri, user?.username],
  );

  if (
    !user ||
    isLoading ||
    (redirectUriData?.company_roles || []).length > 0 ||
    user.company_memberships.length > 0
  ) {
    return <LoadingIcon size={24} />;
  }

  return (
    <VStack spacing={32}>
      <Text size={20} lineHeight={28} weight="medium" color={{ light: "gray-800" }}>
        Create dashboard
      </Text>
      <form onSubmit={onSubmit}>
        <VStack spacing={16}>
          <VStack spacing={8}>
            <Text
              htmlFor="companyName"
              as={Label}
              size={14}
              lineHeight={20}
              weight="medium"
              aria-label="Enter your company name"
              color={{ light: "gray-700" }}>
              Company name
            </Text>
            <TextInput
              aria-label="Enter your company name to use for signing up."
              id="companyName"
              controlSize="large"
              icon="home"
              value={companyName}
              onChange={setCompanyName}
              hasError={createCompanyMutation.isError}
              errorMessage={createCompanyMutation.error?.title}
            />
          </VStack>
          <FormFooter align="end" justify="end">
            <Button disabled={companyName.length < 2} type="submit">
              Continue
            </Button>
          </FormFooter>
        </VStack>
      </form>
    </VStack>
  );
}
