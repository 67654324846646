import React from "react";

import { ErrorContextType } from "../types/NotificationContext";

const NotificationContext = React.createContext<ErrorContextType>({
  notification: null,
  addNotification: () => {},
  removeNotification: () => {},
});

export default NotificationContext;
