import React, { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

type HasViaAndPath = {
  via?: string;
  path?: string;
};

type Without<Q, R> = {
  [K in keyof Q]: K extends keyof R ? never : Q[K];
};

export function useMakeMutationArgsFn<T extends HasViaAndPath>(): (
  q: Without<T, HasViaAndPath>,
) => T {
  const [searchParams] = useSearchParams();
  return useCallback(
    (args) => {
      const via = searchParams.get("via");
      const path = searchParams.get("path");
      return {
        ...args,
        ...(path && { path }),
        ...(via && { via }),
      };
    },
    [searchParams],
  );
}

export function useMakeMutationArgs<T extends HasViaAndPath>(args: Without<T, HasViaAndPath>): T {
  const makeMutationArgs = useMakeMutationArgsFn<T>();
  return useMemo(() => makeMutationArgs(args), [args, makeMutationArgs]);
}
