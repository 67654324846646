import { useMemo } from "react";

import { useSearchParams } from "react-router-dom";

export interface AppProps {
  GoogleSSOURL: string;
  OktaSSOURL: string;
  MicrosoftSSOURL: string;

  // Used for WorkOS-based OAuth
  NewGoogleSSOURL: string;
  NewMicrosoftSSOURL: string;

  ErrorTitle: string;
  Error: string;
}

// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
export const appOptions = window.__SERVER_DATA as AppProps;

export function useAppOptions(): AppProps | undefined {
  return appOptions;
}

const WORKOS_OAUTH_QS_PARAM = "wo";

export enum ViaApps {
  Connect = "connect",
  ConnectAdmin = "connect-admin",
  Dashboard = "dashboard",
  NOC = "noc",
}

export function useViaApp(): ViaApps | undefined {
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    switch (searchParams.get("via")) {
      case "connect":
        return ViaApps.Connect;
      case "connect-admin":
        return ViaApps.ConnectAdmin;
      case "dashboard":
        return ViaApps.Dashboard;
      case "noc":
        return ViaApps.NOC;
      default:
        return undefined;
    }
  }, [searchParams]);
}

export function useIsWorkOS(): boolean {
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    const qsValue = searchParams.get(WORKOS_OAUTH_QS_PARAM);
    return qsValue !== null && ["true", "1"].includes(qsValue.toLowerCase());
  }, [searchParams]);
}

export function useOAuthURLs() {
  const isWorkOS = useIsWorkOS();
  const options = useAppOptions();

  return useMemo(() => {
    if (!options) {
      return {
        GoogleSSOURL: "",
        MicrosoftSSOURL: "",
      };
    }

    const { GoogleSSOURL, MicrosoftSSOURL, NewGoogleSSOURL, NewMicrosoftSSOURL } = options;
    if (isWorkOS) {
      return {
        GoogleSSOURL: NewGoogleSSOURL || GoogleSSOURL,
        MicrosoftSSOURL: NewMicrosoftSSOURL || MicrosoftSSOURL,
      };
    }

    return {
      GoogleSSOURL,
      MicrosoftSSOURL,
    };
  }, [isWorkOS, options]);
}
