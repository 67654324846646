import React from "react";

import { Navigate } from "react-router-dom";

import { useToWithQuery } from "../hooks/useToWithQuery";

export function RedirectToRoot() {
  const linkTo = useToWithQuery("/");

  return <Navigate to={linkTo} />;
}
