import React from "react";

import { HStack, Icon, ServiceButton, ServiceIcon, ServiceIconService } from "@meterup/metric";

import { useAppOptions, useIsWorkOS, useOAuthURLs, useViaApp, ViaApps } from "../appOptions";
import LinkWithQuery from "../LinkWithQuery";

export default function App() {
  const appOptions = useAppOptions();
  const useWorkOS = useIsWorkOS();
  const viaApp = useViaApp();
  const oauthURLs = useOAuthURLs();
  const signInWithEmail = (
    <LinkWithQuery to={useWorkOS ? "/sso" : "/email"}>
      <ServiceButton
        label="Sign in with email"
        icon={<Icon icon="email" />}
        className="m-font-medium"
        size="extra-large"
      />
    </LinkWithQuery>
  );
  if (!appOptions) {
    return signInWithEmail;
  }
  if (viaApp === ViaApps.Connect) {
    return signInWithEmail;
  }

  const { OktaSSOURL } = appOptions;
  const { GoogleSSOURL, MicrosoftSSOURL } = oauthURLs;
  return (
    <>
      <a href={GoogleSSOURL}>
        <ServiceButton
          label="Sign in with Google"
          icon={<ServiceIcon service={ServiceIconService.Google} />}
          className="m-font-medium"
          size="extra-large"
        />
      </a>
      <a href={MicrosoftSSOURL}>
        <ServiceButton
          label="Sign in with Microsoft"
          icon={<ServiceIcon service={ServiceIconService.Microsoft} />}
          className="m-font-medium"
          size="extra-large"
        />
      </a>
      {OktaSSOURL && (
        <a href={OktaSSOURL}>
          <ServiceButton
            label="Sign in with Okta"
            icon={<ServiceIcon service={ServiceIconService.Okta} />}
            className="m-font-medium"
            size="extra-large"
          />
        </a>
      )}
      <div
        className="m-w-full m-text-center m-border-gray-100 m-mt-2.5 "
        style={{ borderBottomWidth: "1px", lineHeight: "0" }}>
        <span className="m-bg-white m-px-2 m-text-xxs m-text-gray-500">OR</span>
      </div>
      {signInWithEmail}
    </>
  );
}
