import React, { useCallback, useMemo, useState } from "react";

import NotificationContext from "../contexts/NotificationContext";
import { NotificationWrapperType } from "../types/NotificationContext";

interface NotificationProviderProps {
  children: React.ReactNode;
  defaultNotification?: NotificationWrapperType;
}

export default function NotificationProvider({
  children,
  defaultNotification,
}: NotificationProviderProps) {
  const [notification, setNotification] = useState<NotificationWrapperType>(
    defaultNotification || null,
  );

  const addNotification = useCallback((err: NotificationWrapperType) => setNotification(err), []);
  const removeNotification = useCallback(() => setNotification(null), []);

  const contextValue = useMemo(
    () => ({
      notification,
      addNotification,
      removeNotification,
    }),
    [notification, addNotification, removeNotification],
  );

  return (
    <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
  );
}

