import React, { FormEvent, useCallback, useState } from "react";

import { Button, TextInput, VStack } from "@meterup/metric";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

type GetSSOUriMutationResult = {
  redirect_uri: string;
};

export default function SSO() {
  const [email, setEmail] = useState("");
  const [searchParams ] = useSearchParams();

  const getSsoUriMutation = useMutation<GetSSOUriMutationResult, unknown, { email: string }>({
    mutationFn: async (vars) => {
      const paramsString = searchParams.toString();
      const url = new URL(`/v1/sso/redirect-uri?${paramsString}`, window.location.origin);
      url.searchParams.set("email", vars.email);
      const r = await axios.get<GetSSOUriMutationResult>(url.toString());
      return r.data;
    },
  });

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      getSsoUriMutation.mutate(
        { email },
        {
          onSuccess(data) {
            window.location.href = data.redirect_uri;
          },
        },
      );
    },
    [email, getSsoUriMutation],
  );

  return (
    <form onSubmit={onSubmit}>
      <VStack spacing={16}>
        <TextInput
          value={email}
          onChange={setEmail}
          icon="email"
          type="email"
          controlSize="large"
          minLength={5}
        />
        <Button type="submit" width="full" size="large" loading={getSsoUriMutation.isLoading}>
          Sign in
        </Button>
      </VStack>
    </form>
  );
}
