import React, { useCallback, useMemo } from "react";

import { api } from "@meterup/proto";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { Outlet, useNavigate } from "react-router-dom";

import IdentifiedUserContext from "../contexts/IdentifiedUserContext";
import { useToWithQuery, useToWithQueryFn } from "../hooks/useToWithQuery";
import { IdentifiedUserContextType } from "../types/IdentifiedUserContext";

type LogoutRequest = {
  via: string;
};

export default function IdentifiedUserProvider() {
  const toWithQueryFn = useToWithQueryFn();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toWithQuery = useToWithQuery(".");
  const { data, error, isLoading, refetch } = useQuery<
    unknown,
    AxiosError<api.Error>,
    api.IdentityResponse
  >(["identity"], () => axios.get("/api-proxy/v1/identity").then((r) => (r.data ? r.data : r)), {
    onSuccess(resp) {
      console.log("OnSuccess", resp);
      if (resp.company_memberships.length > 0) {
        navigate(toWithQueryFn(`/company/${resp.company_memberships[0].company_slug}`));
      }
    },
    onError(err) {
      console.error("OnError", err);
    },
    retry: false,
    refetchInterval: 60_000,
  });
  const logoutMutation = useMutation<unknown, api.Error, LogoutRequest>((req) =>
    axios
      .post(`/v1/logout?via=${req.via}`, {}, { maxRedirects: 0 })
      .then((r) => (r.data ? r.data : r)),
  );
  const logout = useCallback(
    (redirectUri?: string) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { permissions } = data || { permissions: [] };
      if (permissions.length === 0) {
        return;
      }
      const promises = permissions.map(async (name) => logoutMutation.mutateAsync({ via: name }));
      Promise.all(promises).finally(() => {
        queryClient.invalidateQueries(["identity"]).finally(() => {
          window.location.href = redirectUri || toWithQuery;
        });
      });
    },
    [data, logoutMutation, queryClient, toWithQuery],
  );
  const contextValue = useMemo<IdentifiedUserContextType>(
    () => ({
      identity: data,
      logout,
      refetch,
    }),
    [data, logout, refetch],
  );

  return (
    <IdentifiedUserContext.Provider value={contextValue}>
      <Outlet />
    </IdentifiedUserContext.Provider>
  );
}
