import React from "react";

import { Box, Text, TextProps } from "@meterup/metric";
import { Link, LinkProps } from "react-router-dom";

import { useToWithQuery } from "./hooks/useToWithQuery";

type LinkType = LinkProps & React.RefAttributes<HTMLAnchorElement>;

type LinkWithQueryProps = Omit<LinkType, "to"> & {
  to: string;
};

type RelevantTextProps<T extends React.ElementType = "span"> = Pick<TextProps<T>, "color" | "size">;
type StyledLinkWithQueryProps<T extends React.ElementType = "span"> = {
  children: React.ReactNode;
  to: string;
} & RelevantTextProps<T>;

export default function LinkWithQuery({ children, to, ...props }: LinkWithQueryProps) {
  const linkTo = useToWithQuery(to);

  return (
    <Link to={linkTo} {...props}>
      {children}
    </Link>
  );
}

type TP = TextProps<any>;
type GetTPProp<
  PropName extends string,
  Props,
> = PropName extends `${infer P1}.${infer P2}`
  ? P1 extends keyof Props
    ? P2 extends keyof Exclude<Props[P1], undefined>
      ? GetTPProp<P2, Exclude<Props[P1], undefined>>
      : ["Not object", P1, P2, Props[P1], keyof Props[P1], keyof Exclude<Props[P1], undefined>]
    : ["not in Props", P1, Props]
  : PropName extends keyof Props
  ? Exclude<Props[PropName], undefined>
  : never;

type Colors = GetTPProp<"color.dark", TextProps<any>>;

export function StyledLinkWithQuery<T extends React.ElementType = "span">({
  children,
  to,
  ...props
}: StyledLinkWithQueryProps<T>) {
  const linkTo = useToWithQuery(to);
  const colors: Record<"light", Colors> = {
    light: "blue-600",
  };
  const textProps: Record<keyof TP, TP[keyof TP]> = {
    size: 14,
    colors,
    ...props,
  };

  return (
    <Box to={linkTo} as={LinkWithQuery} align="center" display="flex">
      <Text {...textProps}>{children}</Text>
    </Box>
  );
}
