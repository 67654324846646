/* eslint-disable react/no-danger */
import { Alert } from "@meterup/metric";
import * as React from "react";

import { NotificationWrapperType } from "./types/NotificationContext";

interface NotificationProps {
  notification: NotificationWrapperType;
}

export default function Notification({ notification }: NotificationProps) {
  if (!notification) {
    return null;
  }

  const { icon, message, title, type } = notification;

  if (type === "error") {
    return (
      <Alert
        copy={<div dangerouslySetInnerHTML={{ __html: message }} />}
        heading={title}
        icon="warning"
        variant="negative"
      />
    );
  }

  if (type === "success") {
    return <Alert copy={message} heading={title} icon={icon} variant="positive" />;
  }

  return <Alert copy={message} heading={title} icon={icon} variant="neutral" />;
}
