import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  Alert,
  Button,
  HStack,
  Icon,
  LoadingIcon,
  Text,
  TextInput,
  theme,
  VStack,
} from "@meterup/metric";
import { api } from "@meterup/proto";
import { CreateUserOTPAuthenticationValidationRequest } from "@meterup/proto/esm/api";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RingBuffer } from "ring-buffer-ts";

import useIdentifiedUser from "../hooks/useIdentifiedUser";
import { useLoginWithEmailMutation } from "../hooks/useLoginWithEmailMutation";
import { useToWithQuery, useToWithQueryFn } from "../hooks/useToWithQuery";
import { StyledLinkWithQuery } from "../LinkWithQuery";
import { logError } from "../logError";
import { OTPAuthenticationValidationResponse } from "../vendor/portal";

const { styled } = theme;

const CharacterWrapper = styled("div", {
  maxWidth: "75px",
  "& *": {
    fontWeight: "$medium !important",
  },
  "& input": {
    fontWeight: "$bold !important",
    color: "$gray-700",
    textAlign: "center",
  },
});

type OTPCodeInputProps = {
  disabled?: boolean;
  hasError?: boolean;
  numDigits?: number;
  onChange: (val: string[]) => void;
  onSubmit?: () => void;
  onUndo?: () => void;
  value: string[];
};

function OTPCodeInput({
  disabled,
  hasError,
  numDigits = 6,
  onChange,
  onSubmit,
  onUndo,
  value,
}: OTPCodeInputProps) {
  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(numDigits));
  const handleKeyDown = useCallback(
    (idx: number) => (event: React.KeyboardEvent) => {
      const { code: keyCode, key, metaKey, altKey, ctrlKey, shiftKey } = event;
      if (keyCode === "Backspace") {
        if (value[idx] === "") {
          inputRefs.current[Math.max(idx - 1, 0)]?.focus();
          event.preventDefault();
          return false;
        }
        onChange?.([...value.slice(0, idx), "", ...value.slice(idx + 1)]);
      }
      if (keyCode === "Enter") {
        onSubmit?.();
        event.preventDefault();
        return false;
      }
      if (key === "z" && (metaKey || ctrlKey)) {
        if (shiftKey) {
          // TODO: redo
        } else {
          onUndo?.();
        }
        event.preventDefault();
        return false;
      }
      if (keyCode === "Tab" || metaKey || altKey || ctrlKey) {
        return true;
      }
      if (!keyCode.startsWith("Digit") && !keyCode.startsWith("Key")) {
        event.preventDefault();
        return false;
      }
      const char = key.toUpperCase();
      onChange?.([...value.slice(0, idx), char, ...value.slice(idx + 1)]);
      inputRefs.current[Math.min(idx + 1, numDigits - 1)]?.focus();
      return true;
    },
    [onChange, value, numDigits, onSubmit, onUndo],
  );
  const handlePaste = useCallback(
    (idx: number) => (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedText = e.clipboardData.getData("text");
      const chars = pastedText.split("");
      const remainingChars = numDigits - idx;
      const numCharsToPaste = Math.min(chars.length, remainingChars);
      onChange?.([
        ...value.slice(0, idx),
        ...chars.slice(0, numCharsToPaste),
        ...value.slice(idx + numCharsToPaste, numDigits),
      ]);
    },
    [numDigits, onChange, value],
  );
  useEffect(() => {
    const firstEmptyIdx = value.findIndex((char) => char === "");
    if (firstEmptyIdx !== -1) {
      inputRefs.current[firstEmptyIdx]?.focus();
      return;
    }
    inputRefs.current[numDigits - 1]?.focus();
  }, [numDigits, onSubmit, value]);

  return (
    <HStack spacing={8}>
      {value.map((char, index) => (
        <CharacterWrapper key={`char-wrapper-${index}`}>
          <TextInput
            controlSize="large"
            disabled={disabled}
            onKeyDown={handleKeyDown(index)}
            hasError={hasError}
            key={`char-${index}`}
            aria-label={`OTP Code Character ${index + 1}`}
            isRequired
            onPaste={handlePaste(index)}
            ref={(ref: HTMLLabelElement | null) => {
              let inputChild: HTMLInputElement | null = null;
              if (ref) {
                inputChild = ref.querySelector("input");
              }
              inputRefs.current[index] = inputChild;
            }}
            value={char}
          />
        </CharacterWrapper>
      ))}
    </HStack>
  );
}

const BASE_OTP_ARY: ReadonlyArray<string> = Array(6).fill("");

export default function ConfirmCode() {
  const { email: encodedEmail } = useParams<{ email: string }>();
  const location = useLocation();
  const email = useMemo(() => decodeURIComponent((encodedEmail || "").trim()), [encodedEmail]);
  const [otpCodeAry, setOtpCodeAry] = useState(BASE_OTP_ARY as string[]);
  const inputsRef = useRef(RingBuffer.fromArray<string[]>([BASE_OTP_ARY as string[]], 10));
  const toValidate = useToWithQuery(`/v1/stytch/validate`);
  const submitLoginMutation = useLoginWithEmailMutation();
  const navigate = useNavigate();
  const toWithQuery = useToWithQueryFn();
  const identity = useIdentifiedUser();
  const submitOTPValidationMutation = useMutation<
    OTPAuthenticationValidationResponse,
    AxiosError<api.Error>,
    CreateUserOTPAuthenticationValidationRequest
  >(
    (params) =>
      axios.post(toValidate, params).then((r) => r.data as OTPAuthenticationValidationResponse),
    {
      onSuccess: async (data) => {
        const { company_roles: companyRoles } = data;

        if (companyRoles.length === 0) {
          navigate(toWithQuery("/company"));
        }
        await identity.refetch();
      },
      onError: (err) => logError(err),
      retry: false,
    },
  );

  const onSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      const otpCode = otpCodeAry.join("");
      submitOTPValidationMutation.mutate({
        email: email!,
        code: otpCode,
      });
    },
    [email, otpCodeAry, submitOTPValidationMutation],
  );
  const updateOTPCodeArray = useCallback(
    (newAry: string[]) => {
      setOtpCodeAry(newAry);
      inputsRef.current.add(newAry);
      const firstEmptyIdx = newAry.findIndex((char) => char === "");
      if (firstEmptyIdx === -1) {
        submitOTPValidationMutation.mutate({
          email: email!,
          code: newAry.join(""),
        });
      }
    },
    [email, submitOTPValidationMutation],
  );
  const onClickResendCode = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      setOtpCodeAry(BASE_OTP_ARY as string[]);
      submitOTPValidationMutation.reset();
      submitLoginMutation.mutate({ email });
    },
    [email, submitLoginMutation, submitOTPValidationMutation],
  );
  const onUndo = useCallback(() => {
    if (inputsRef.current.isEmpty()) {
      return;
    }
    inputsRef.current.removeLast();
    const last = inputsRef.current.getLast();
    if (last) {
      setOtpCodeAry(last);
    }
  }, []);

  useEffect(() => {
    if (inputsRef.current.isEmpty()) {
      inputsRef.current.add(BASE_OTP_ARY.concat());
    }
  }, [onSubmit, otpCodeAry]);
  let submitOTPValidationErrorCopy: string | null = null;
  if (submitOTPValidationMutation.isError) {
    const { response } = submitOTPValidationMutation.error;
    const detail = response?.data?.detail;
    if (detail && detail.length > 0) {
      submitOTPValidationErrorCopy = detail;
    } else {
      submitOTPValidationErrorCopy = "Invalid code. Please confirm or resend code.";
    }
  }

  return (
    <VStack spacing={32}>
      {(location.state && location.state?.email) || submitLoginMutation.isSuccess ? (
        <Alert
          copy={`A 6-digit verification code was sent to ${email}. Enter the code below to complete your login.`}
          heading="Check your inbox"
          icon="email"
          variant="neutral"
        />
      ) : null}

      <VStack spacing={16}>
        <VStack spacing={8}>
          <Text color={{ light: "gray-900" }} size={14} lineHeight={20} weight="medium">
            <HStack spacing={4} align="center">
              <span>Verification code</span>
              {(submitOTPValidationMutation.isLoading || submitOTPValidationMutation.isSuccess) && (
                <LoadingIcon size={24} />
              )}
            </HStack>
          </Text>
          <form onSubmit={onSubmit}>
            <OTPCodeInput
              onChange={updateOTPCodeArray}
              onSubmit={onSubmit}
              onUndo={onUndo}
              value={otpCodeAry}
            />
            <Text color={{ light: "gray-600" }} size={12} lineHeight={16} weight="regular">
              Enter the verification code sent to your email.
            </Text>
          </form>
        </VStack>
        {submitOTPValidationMutation.isError ? (
          <Alert variant="negative" copy={submitOTPValidationErrorCopy} />
        ) : null}
      </VStack>
      <HStack spacing={8}>
        <StyledLinkWithQuery to={toWithQuery("/email")}>
          <HStack spacing={4} align="center">
            <Icon icon="arrowLeft" size={10} />
            <span>Back</span>
          </HStack>
        </StyledLinkWithQuery>
        <Button
          onClick={onClickResendCode}
          variant="tertiary"
          css={{
            color: "$blue-600",
            boxShadow: "unset",
            border: "unset",
            padding: 0,
            "&:hover, &:focus, &:focus-within, &:focus-visible": {
              boxShadow: "unset",
              border: "unset",
            },
            "& span, & svg": { color: "$blue-600" },
          }}>
          Resend code
        </Button>
      </HStack>
    </VStack>
  );
}
