import * as Sentry from "@sentry/react";

export const logError = <T>(error: T): T => {
  if (import.meta.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  Sentry.captureException(error);
  return error;
};
